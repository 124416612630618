import React from "react"
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import ContentFeed from '../components/content-feed';

import * as _ from 'lodash'

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {

  const siteTitle = data.site.siteMetadata.title;
  const siteDescription = data.site.siteMetadata.description;

  const posts = data.allMarkdownRemark.edges;
  const { currentPage, numTagPages } = pageContext

  const tagHeader = `${data.allMarkdownRemark.totalCount} post${
    data.allMarkdownRemark.totalCount === 1 ? "" : "s"
    } tagged with "${pageContext.tag}"`

  return (
    <Layout location={data.location}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={pageContext.tag + ' | ' + siteTitle}
      />

      <h3 style={{
        textAlign: `center`
      }}>{tagHeader}</h3>

      <ContentFeed content={posts} currentPage={currentPage} numPages={numTagPages} path={`tag/${_.kebabCase(pageContext.tag)}`} />

    </Layout>
  )

}

export default Tags

export const pageQuery = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      limit: $limit,
      skip: $skip,
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { 
        frontmatter: { 
          tags: { in: [$tag] },
          draft: { ne: true }
        } 
      }
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            date_unformatted: date
            date_formatted: date(formatString: "MMMM DD, YYYY")
            published_unformatted: date
            published_formatted: date(formatString: "MMMM DD, YYYY")
            updated_unformatted: updated
            updated_formatted: updated(formatString: "MMMM DD, YYYY")
            author
            title
            url
            tags
            categories
            featured_image
          }
        }
      }
    }
  }
`